import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <h1>Page Not Found</h1>
  </Layout>
);

export default NotFoundPage;
